import { ResourceProps } from '@pankod/refine-core';
import { envModuleFlagVault } from 'libs/helpers/uac';

import { FEATURE } from 'config/feature';
import { LoadingScreen } from 'components';
import loadable from '@loadable/component';

/* PLOP_INJECT_LOADABLE */
const SettingLogisticFeeEdit = loadable(
  () => import(/* webpackChunkName: "SettingLogisticFeeEdit" */ 'pages/setting/logisticFee/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingDefaultPlafonCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingDefaultPlafonCreate" */ 'pages/setting/defaultPlafon/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingDefaultPlafonEdit = loadable(
  () =>
    import(/* webpackChunkName: "SettingDefaultPlafonEdit" */ 'pages/setting/defaultPlafon/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingDefaultPlafonList = loadable(
  () =>
    import(/* webpackChunkName: "SettingDefaultPlafonList" */ 'pages/setting/defaultPlafon/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingDefaultPlafonShow = loadable(
  () =>
    import(/* webpackChunkName: "SettingDefaultPlafonShow" */ 'pages/setting/defaultPlafon/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingSupplykuList = loadable(
  () => import(/* webpackChunkName: "SettingSupplykuList" */ 'pages/setting/supplyku/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingSupplyEfreshList = loadable(
  () => import(/* webpackChunkName: "SettingSupplyEfreshList" */ 'pages/setting/supplyEfresh/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingAreaDropshipList = loadable(
  () => import(/* webpackChunkName: "SettingAreaDropshipList" */ 'pages/setting/areaDropship/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const settingAreaPaymentVA = loadable(
  () => import(/* webpackChunkName: "settingAreaPaymentVA" */ 'pages/setting/areaPaymentVA/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingAreaLapakIkanList = loadable(
  () =>
    import(/* webpackChunkName: "SettingAreaLapakIkanList" */ 'pages/setting/areaLapakIkan/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingAreaPayARKabayanList = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingAreaPayARKabayanList" */ 'pages/setting/areaPayARKabayan/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingAreaDigitalSignList = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingAreaDigitalSignList" */ 'pages/setting/areaDigitalSign/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingAreaENotaList = loadable(
  () => import(/* webpackChunkName: "SettingAreaENotaList" */ 'pages/setting/areaENota/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingAreaARAPSamedayPaymentList = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingAreaARAPSamedayPaymentList" */ 'pages/setting/areaARAPSamedayPayment/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingAccessManagementCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingAccessManagementCreate" */ 'pages/setting/accessManagement/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingAccessManagementEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingAccessManagementEdit" */ 'pages/setting/accessManagement/edit'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingAccessManagementList = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingAccessManagementList" */ 'pages/setting/accessManagement/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingDownstreamDigitalPaymentList = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingDownstreamDigitalPaymentList" */ 'pages/setting/downstreamDigitalPayment/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingReferralsCreate = loadable(
  () => import(/* webpackChunkName: "SettingReferralsCreate" */ 'pages/setting/referrals/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingReferralsEdit = loadable(
  () => import(/* webpackChunkName: "SettingReferralsEdit" */ 'pages/setting/referrals/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingReferralsList = loadable(
  () => import(/* webpackChunkName: "SettingReferralsList" */ 'pages/setting/referrals/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingReferralsShow = loadable(
  () => import(/* webpackChunkName: "SettingReferralsShow" */ 'pages/setting/referrals/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingSupplyPointsCreate = loadable(
  () =>
    import(/* webpackChunkName: "SettingSupplyPointsCreate" */ 'pages/setting/supplyPoints/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingSupplyPointsEditNew = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingSupplyPointsEditNew" */ 'pages/setting/supplyPoints/edit.new'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingSupplyPointsEdit = loadable(
  () => import(/* webpackChunkName: "SettingSupplyPointsEdit" */ 'pages/setting/supplyPoints/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingSupplyPointsList = loadable(
  () => import(/* webpackChunkName: "SettingSupplyPointsList" */ 'pages/setting/supplyPoints/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const SettingSupplyPointsShow = loadable(
  () => import(/* webpackChunkName: "SettingSupplyPointsShow" */ 'pages/setting/supplyPoints/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const Sliders = loadable(() => import('@onefish/icons-react/src/icons/phosphor/Sliders'));

const SettingAreaDownstreamAppList = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingAreaDownstreamAppList" */ 'pages/setting/areaDownstreamApp/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);

export const settingResources: ResourceProps[] = [
  {
    name: 'setting',
    icon: <Sliders size={18} />,
  },
  /* PLOP_INJECT_RESOURCE */
  {
    name: 'setting_area-downstream-app',
    parentName: 'setting',
    list: SettingAreaDownstreamAppList,
    options: {
      label: 'Area downstream app',
      route: 'area-downstream-app',
    },
  },
  {
    name: 'setting_logistic-fee',
    parentName: 'setting',
    list: SettingLogisticFeeEdit,
    options: {
      label: 'Logistic fee',
      route: 'logistic-fee',
    },
  },
  {
    name: 'setting_default-plafon',
    parentName: 'setting',
    create: SettingDefaultPlafonCreate,
    edit: SettingDefaultPlafonEdit,
    list: SettingDefaultPlafonList,
    show: SettingDefaultPlafonShow,
    options: {
      label: 'Default plafon',
      route: 'default-plafon',
    },
  },
  {
    name: 'setting_supplyku',
    parentName: 'setting',
    list: SettingSupplykuList,
    options: {
      label: 'SupplyKu',
      route: 'supplyku',
    },
  },
  {
    name: 'setting_supply-efresh',
    parentName: 'setting',
    list: SettingSupplyEfreshList,
    options: {
      label: 'Supply efresh',
      route: 'supply-efresh',
    },
  },
  {
    name: 'setting_area-dropship',
    parentName: 'setting',
    list: SettingAreaDropshipList,
    options: {
      label: 'Area Dropship',
      route: 'area-dropship',
    },
  },
  {
    name: 'setting_area-payment-va',
    parentName: 'setting',
    list: settingAreaPaymentVA,
    options: {
      label: 'Area Payment',
      route: 'area-payment-va',
    },
  },
  {
    name: 'setting_area-lapak-ikan',
    parentName: 'setting',
    list: SettingAreaLapakIkanList,
    options: {
      label: 'Area Lapak Ikan',
      route: 'area-lapak-ikan',
    },
  },
  {
    name: 'setting_area-pay-ar-kabayan',
    parentName: 'setting',
    list: SettingAreaPayARKabayanList,
    options: {
      label: 'Area Pay AR Kabayan',
      route: 'area-pay-ar-kabayan',
    },
  },
  {
    name: 'setting_area-digital-sign',
    parentName: 'setting',
    list: SettingAreaDigitalSignList,
    options: {
      label: 'Area Digital Signs',
      route: 'area-digital-sign',
    },
  },
  {
    name: 'setting_area-e-nota',
    parentName: 'setting',
    list: SettingAreaENotaList,
    options: {
      label: 'Area e-Nota',
      route: 'area-e-nota',
    },
  },
  {
    name: 'setting_area-ar-ap-sameday-payment',
    parentName: 'setting',
    list: SettingAreaARAPSamedayPaymentList,
    options: {
      label: 'Area AR/AP Sameday Payment',
      route: 'area-ar-ap-sameday-payment',
    },
  },
  {
    name: 'setting_access-management',
    parentName: 'setting',
    create: SettingAccessManagementCreate,
    edit: SettingAccessManagementEdit,
    list: SettingAccessManagementList,
    canDelete: true,
    options: {
      label: 'Access management',
      route: 'access-management',
    },
  },
  {
    name: 'setting_downstream-digital-payment',
    parentName: 'setting',
    list: SettingDownstreamDigitalPaymentList,
    options: {
      label: 'Downstream digital payment',
      route: 'downstream-digital-payment',
    },
  },
  {
    name: 'setting_referrals',
    parentName: 'setting',
    create: SettingReferralsCreate,
    edit: SettingReferralsEdit,
    list: SettingReferralsList,
    show: SettingReferralsShow,
    options: {
      label: 'referrals',
      route: 'referrals',
    },
  },
  {
    name: 'setting_supply-points',
    parentName: 'setting',
    create: SettingSupplyPointsCreate,
    edit: FEATURE.ENABLE_FEATURE_NEW_SUPPLY_POINT_EDIT
      ? SettingSupplyPointsEditNew
      : SettingSupplyPointsEdit,
    list: SettingSupplyPointsList,
    show: SettingSupplyPointsShow,
    canDelete: true,
    options: {
      label: 'Supply Point',
      route: 'supply-points',
    },
  },
].filter(envModuleFlagVault);
