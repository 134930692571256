import loadable from '@loadable/component';
import { ResourceProps } from '@pankod/refine-core';
import { LoadingScreen } from 'components';
import { envModuleFlagVault } from 'libs/helpers/uac';

/* PLOP_INJECT_LOADABLE */
const SalesDownstreamOrderCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "SalesDownstreamOrderCreate" */ 'pages/sales/downstream-order/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const SalesDownstreamOrderEdit = loadable(
  () =>
    import(/* webpackChunkName: "SalesDownstreamOrderEdit" */ 'pages/sales/downstream-order/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const SalesDownstreamOrderList = loadable(
  () =>
    import(/* webpackChunkName: "SalesDownstreamOrderList" */ 'pages/sales/downstream-order/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const SalesDownstreamOrderShow = loadable(
  () =>
    import(/* webpackChunkName: "SalesDownstreamOrderShow" */ 'pages/sales/downstream-order/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const SalesFismoRequestSupplyList = loadable(
  () =>
    import(
      /* webpackChunkName: "SalesFismoRequestSupplyList" */ 'pages/sales/fismoRequestSupply/list'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const SalesFismoRequestSupplyShow = loadable(
  () =>
    import(
      /* webpackChunkName: "SalesFismoRequestSupplyShow" */ 'pages/sales/fismoRequestSupply/show'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const SalesOrderCreate = loadable(
  () => import(/* webpackChunkName: "SalesOrderList" */ 'pages/sales/order/create'),
  {
    fallback: <LoadingScreen />,
  },
);
const SalesOrderEdit = loadable(
  () => import(/* webpackChunkName: "SalesOrderList" */ 'pages/sales/order/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const SalesOrderList = loadable(
  () => import(/* webpackChunkName: "SalesOrderList" */ 'pages/sales/order/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const SalesOrderShow = loadable(
  () => import(/* webpackChunkName: "SalesOrderShow" */ 'pages/sales/order/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const SalesSalesQuoteList = loadable(
  () => import(/* webpackChunkName: "SalesSalesQuoteList" */ 'pages/sales/quote/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const SalesSalesQuoteShow = loadable(
  () => import(/* webpackChunkName: "SalesSalesQuoteShow" */ 'pages/sales/quote/show'),
  {
    fallback: <LoadingScreen />,
  },
);
const SalesDownstreamOrderCreateV2 = loadable(
  () =>
    import(
      /* webpackChunkName: "SalesDownstreamOrderCreateV2" */ 'pages/sales/downstreamOrder/create'
    ),
  {
    fallback: <LoadingScreen />,
  },
);
const SalesDownstreamOrderEditV2 = loadable(
  () =>
    import(/* webpackChunkName: "SalesDownstreamOrderEditV2" */ 'pages/sales/downstreamOrder/edit'),
  {
    fallback: <LoadingScreen />,
  },
);
const SalesDownstreamOrderListV2 = loadable(
  () =>
    import(/* webpackChunkName: "SalesDownstreamOrderListV2" */ 'pages/sales/downstreamOrder/list'),
  {
    fallback: <LoadingScreen />,
  },
);
const SalesDownstreamOrderShowV2 = loadable(
  () =>
    import(/* webpackChunkName: "SalesDownstreamOrderShowV2" */ 'pages/sales/downstreamOrder/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const SalesSuplaiTerbukaList = loadable(
  () => import(/* webpackChunkName: "SalesSuplaiTerbukaList" */ 'pages/sales/suplaiTerbuka/list'),
  {
    fallback: <LoadingScreen />,
  },
);

const SalesSuplaiTerbukaShow = loadable(
  () => import(/* webpackChunkName: "SalesSuplaiTerbukaShow" */ 'pages/sales/suplaiTerbuka/show'),
  {
    fallback: <LoadingScreen />,
  },
);

const ShoppingCart = loadable(() => import('@onefish/icons-react/src/icons/phosphor/ShoppingCart'));

export const salesResources: ResourceProps[] = [
  {
    name: 'sales',
    icon: <ShoppingCart size={18} />,
  },
  /* PLOP_INJECT_RESOURCE */
  {
    name: 'sales_suplai_terbuka',
    parentName: 'sales',
    list: SalesSuplaiTerbukaList,
    show: SalesSuplaiTerbukaShow,
    options: {
      label: 'suplai terbuka',
      route: 'suplai_terbuka',
    },
  },
  {
    name: 'sales_quotes',
    parentName: 'sales',
    list: SalesSalesQuoteList,
    show: SalesSalesQuoteShow,
    options: {
      label: 'quotes',
      route: 'quotes',
    },
  },
  {
    name: 'sales_orders',
    parentName: 'sales',
    create: SalesOrderCreate,
    edit: SalesOrderEdit,
    list: SalesOrderList,
    show: SalesOrderShow,
    options: {
      label: 'orders',
      route: 'orders',
    },
  },
  {
    name: 'sales_downstream-orders',
    parentName: 'sales',
    create: SalesDownstreamOrderCreate,
    edit: SalesDownstreamOrderEdit,
    list: SalesDownstreamOrderList,
    show: SalesDownstreamOrderShow,
    options: {
      label: 'downstream orders',
      route: 'downstream-orders',
    },
  },
  {
    name: 'sales_downstream-orders-v2',
    parentName: 'sales',
    create: SalesDownstreamOrderCreateV2,
    edit: SalesDownstreamOrderEditV2,
    list: SalesDownstreamOrderListV2,
    show: SalesDownstreamOrderShowV2,
    options: {
      label: '[New] Downstream orders',
      route: 'downstream-order-v2',
    },
  },
  {
    name: 'sales_fismo-request',
    parentName: 'sales',
    list: SalesFismoRequestSupplyList,
    show: SalesFismoRequestSupplyShow,
    options: {
      label: 'Fismo request supply',
      route: 'fismo-request',
    },
  },
].filter(envModuleFlagVault);
