export const FEATURE = {
  ENABLE_FEATURE_AUTH2: ['true', '1'].includes(process.env.REACT_APP_ENABLE_FEATURE_AUTH2 || ''),
  ENABLE_FEATURE_BUYER_LEAD_OUTLETS_FORM_V2: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_BUYER_LEAD_OUTLETS_FORM_V2 || '',
  ),
  ENABLE_BUYER_LEAD_REDFLAG_IMMUNE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_BUYER_LEAD_REDFLAG_IMMUNE || '',
  ),
  ENABLE_FEATURE_BUYER_LEADS_CHANNEL: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_BUYER_LEADS_CHANNEL || '',
  ),
  ENABLE_FEATURE_BUYER_LEADS_HISTORY: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_BUYER_LEADS_HISTORY || '',
  ),
  ENABLE_FEATURE_BUYER_LEADS_HUB: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_BUYER_LEADS_HUB || '',
  ),
  ENABLE_FEATURE_BUYER_LEADS_INPUT_LEAD_OUTLETS: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_BUYER_LEADS_INPUT_LEAD_OUTLETS || '',
  ),
  ENABLE_FEATURE_BUYER_LEADS_KYC_FORM: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_BUYER_LEADS_KYC_FORM || '',
  ),
  ENABLE_FEATURE_BUYER_LEADS_LOGISTIC: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_BUYER_LEADS_LOGISTIC || '',
  ),
  ENABLE_FEATURE_BUYER_LEADS_MO: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_BUYER_LEADS_MO || '',
  ),
  ENABLE_FEATURE_CAMPAIGN_MINMAX_UNIT_TRANSACTION: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_CAMPAIGN_MINMAX_UNIT_TRANSACTION || '',
  ),
  ENABLE_FEATURE_CAMPAIGN_SUPPLIER_TYPE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_CAMPAIGN_PROMO_FARMER_SUPPLIER_TYPE || '',
  ),
  ENABLE_FEATURE_CAMPAIGN_PROMO_SUPPLY_SOURCE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_CAMPAIGN_PROMO_SUPPLY_SOURCE || '',
  ),
  ENABLE_FEATURE_CAMPAIGN_PROMO_OLD_SEGMENTATION: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_CAMPAIGN_PROMO_OLD_SEGMENTATION || '',
  ),
  ENABLE_FEATURE_CAMPAIGN_PROMO_VARIANT_RUPIAH: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_CAMPAIGN_PROMO_VARIANT_RUPIAH || '',
  ),
  ENABLE_FEATURE_CAMPAIGN_SEGMENTATION_METHOD: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_CAMPAIGN_SEGMENTATION_METHOD || '',
  ),
  ENABLE_FEATURE_CAMPAIGN_TRANSACTION_PROMO_UNIT: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_CAMPAIGN_TRANSACTION_PROMO_UNIT || '',
  ),
  ENABLE_FEATURE_CAMPAIGN_PROMO_FARMER_FILTER_V2: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_CAMPAIGN_PROMO_FARMER_FILTER_V2 || '',
  ),
  ENABLE_FEATURE_DOWNSTREAM_ORDER_WRT_DATE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_DOWNSTREAM_ORDER_WRT_DATE || '',
  ),
  ENABLE_FEATURE_FILTER_ORDER_TYPE_DOWNSTREAM: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_FILTER_ORDER_TYPE_DOWNSTREAM || '',
  ),
  ENABLE_FEATURE_FILTER_SUPPLY_RECOMMENDATION_HUB: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_FILTER_SUPPLY_RECOMMENDATION_HUB || '',
  ),
  ENABLE_FEATURE_INBOUND_SEPARATE_QTY: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_HUB_MANAGEMENT_INBOUND_SPLIT_QTY || '',
  ),
  ENABLE_FEATURE_OUTBOUND_DOCUMENT_PARTIAL_REJECT: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_HUB_MANAGEMENT_OUTBOUND_DOCUMENT_PARTIAL_REJECT || '',
  ),
  ENABLE_FEATURE_OUTBOUND_FISMO: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_HUB_MANAGEMENT_OUTBOUND_FISMO || '',
  ),
  ENABLE_FEATURE_OUTBOUND_SHUTTLE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_HUB_MANAGEMENT_OUTBOUND_SHUTTLE || '',
  ),
  ENABLE_FEATURE_INBOUND_REQUEST_FORM_V2: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_INBOUND_REQUEST_FORM_V2 || '',
  ),
  ENABLE_FEATURE_BUYER_LEADS_KTP_FLB: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_LEADS_KTP_FLB || '',
  ),
  ENABLE_FEATURE_BUYER_LEADS_KYC_DASHBOARD: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_LEADS_KYC_DASHBOARD || '',
  ),
  ENABLE_FEATURE_BUYER_LEADS_LAT_LONG: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_LEADS_LAT_LONG || '',
  ),
  ENABLE_FEATURE_NEW_DIGITAL_SIGN: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_NEW_DIGITAL_SIGN || '',
  ),
  ENABLE_FEATURE_NEW_EXPORT_SALES_ORDER: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_NEW_EXPORT_SALES_ORDER || '',
  ),
  ENABLE_FEATURE_NEW_SUPPLY_POINT_EDIT: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_NEW_SUPPLY_POINT_EDIT || '',
  ),
  ENABLE_FEATURE_PRICING_BASED_PRICE_FLB_TO_FLP: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_PRICING_BASED_PRICE_FLB_TO_FLP || '',
  ),
  ENABLE_PROMO_BASED_PAYMENT_METHOD: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_PROMO_BASED_PAYMENT_METHOD || '',
  ),
  ENABLE_PROMO_BASED_SKU: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_PROMO_BASED_SKU || '',
  ),
  ENABLE_PROMO_IMPORT_LEADS: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_PROMO_IMPORT_LEADS || '',
  ),
  ENABLE_PROMO_IMPORT_POINT: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_PROMO_IMPORT_POINTS || '',
  ),
  ENABLE_PROMO_MAX_AMOUNT_VALUE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_PROMO_MAX_AMOUNT_VALUE || '',
  ),
  ENABLE_PROMO_PMS: ['true', '1'].includes(process.env.REACT_APP_ENABLE_FEATURE_PROMO_PMS || ''),
  ENABLE_PROMO_VALIDATE_BASED_PAYMENT_METHOD: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_PROMO_VALIDATE_BASED_PAYMENT_METHOD || '',
  ),
  ENABLE_PROMO_VALIDATE_BASED_SKU: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_PROMO_VALIDATE_BASED_SKU || '',
  ),
  ENABLE_FEATURE_PROMO_PMS_V2: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_PROMO_PMS_V2 || '',
  ),
  ENABLE_FEATURE_PROMO_FARMER_PMS_V2: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_PROMO_FARMER_PMS_V2 || '',
  ),
  ENABLE_FEATURE_QC_HUB_SEPARATED_DISPLAY: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_QUALITY_CONTROL_HUB_SEPARATED_DISPLAY || '',
  ),
  ENABLE_FEATURE_QC_NEW_COVERAGE_FIELD: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_QUALITY_CONTROL_NEW_COVERAGE_FIELD || '',
  ),
  ENABLE_FEATURE_EDIT_RESTRICTION_DELIVERY_ASSIGNMENT: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_EDIT_RESTRICTION_DELIVERY_ASSIGNMENT || '',
  ),
  ENABLE_FEATURE_EDIT_RESTRICTION_PICKUP_ASSIGNMENT: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_EDIT_RESTRICTION_PICKUP_ASSIGNMENT || '',
  ),
  ENABLE_FEATURE_RANGE_ETA_TIME: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_RANGE_ETA_TIME || '',
  ),
  ENABLE_FEATURE_RBAC: ['true', '1'].includes(process.env.REACT_APP_ENABLE_FEATURE_RBAC || ''),
  ENABLE_FEATURE_RBAC_V2: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_RBAC_V2 || '',
  ),
  ENABLE_FEATURE_RESYNC_ORDER: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_RESYNC_ORDER || '',
  ),
  ENABLE_FEATURE_SALES_DOWNSTREAM_ORDER_DISABLE_SELECT_LOGISTIC: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_DOWNSTREAM_ORDER_DISABLE_SELECT_LOGISTIC || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_AMOUNT_RUPIAH: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_AMOUNT_RUPIAH || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_ATTACHMENT_STATUS: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_ATTACHMENT_STATUS || '',
  ),
  ENABLE_FEATURE_ORDER_DOWNSTREAM_EDIT_LOGISTIC_FEE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM_EDIT_LOGISTIC_FEE || '',
  ),
  ENABLE_FEATURE_ORDER_DOWNSTREAM_LOGISTIC_FEE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM_LOGISTIC_FEE || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM_MAX_ETA_DATE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM_MAX_ETA_DATE || '',
  ),
  ENABLE_FEATURE_ORDER_DOWNSTREAM_RECEIVE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM_RECEIVE || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM_SOFT_DELETE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM_SOFT_DELETE || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM_SUPPLY_RECOMMENDATION: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM_SUPPLY_RECOMMENDATION || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM_UNIT_LOAD_DEVICE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM_UNIT_LOAD_DEVICE || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM_VIRTUAL_ACCOUNT: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM_VIRTUAL_ACCOUNT || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM_WRT_ORDER: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM_WRT_ORDER || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_DOWNSTREAM || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_EDIT_PRICE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_EDIT_PRICE || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_EXCLUDE_HUB: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_EXCLUDE_HUB || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_EXCLUDE_SHUTTLE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_EXCLUDE_SHUTTLE || '',
  ),
  ENABLE_SALES_ORDER_MULTI_APPROVE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_MULTI_APPROVE || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_OPTIONAL_SUPPLY_LOGISTIC: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_OPTIONAL_SUPPLY_LOGISTIC || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_PROMO_BUDGET_VALIDATE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_PROMO_BUDGET_VALIDATE || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_QUANTITY_MIN_50KG: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_QUANTITY_MIN_50KG || '',
  ),
  ENABLE_SALES_ORDER_REDFLAG_IMMUNE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_REDFLAG_IMMUNE || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_SOFT_DELETE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_SOFT_DELETE || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_STATUS_MITRA_OPERATIONAL: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_STATUS_MITRA_OPERATIONAL || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_SUPPLY_RECOMMENDATION_EXCLUDE_HUB: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_SUPPLY_RECOMMENDATION_EXCLUDE_HUB || '',
  ),
  ENABLE_SALES_ORDER_RECOMMENDATION: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_SUPPLY_RECOMMENDATION || '',
  ),
  ENABLE_FEATURE_SALES_ORDER_SUPPLY_SOURCE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_ORDER_SUPPLY_SOURCE || '',
  ),
  ENABLE_FEATURE_SENTRY_BROWSER_TRACING_WITH_ROUTING: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SENTRY_BROWSER_TRACING_WITH_ROUTING || '',
  ),
  ENABLE_FEATURE_SENTRY_BROWSER_TRACING_WITH_TARGET: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SENTRY_BROWSER_TRACING_WITH_TARGET || '',
  ),
  ENABLE_FEATURE_SETTING_SUPPLYKU_BUYER_AREA_COVERAGE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SETTING_SUPPLYKU_BUYER_AREA_COVERAGE || '',
  ),
  ENABLE_FEATURE_SSO: ['true', '1'].includes(process.env.REACT_APP_ENABLE_FEATURE_SSO || ''),
  ENABLE_FEATURE_SUPPLY_FARMER_PONDS: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SUPPLY_FARMER_PONDS || '',
  ),
  ENABLE_FEATURE_VALIDATION_SKU_ORDER_AND_RECOMMENDATION: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_VALIDATION_SKU_ORDER_AND_RECOMMENDATION || '',
  ),
  ENABLE_MAINTENANCE_MODE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_MAINTENANCE_MODE || '',
  ),
  ENABLE_CALCULATE_PROMO_IMPROVEMENT: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_CALCULATE_PROMO_IMPROVEMENT || '',
  ),
  ENABLE_FEATURE_SALES_DOWNSTREAM_ORDERS_V2_MULTIPLE_SUPPLY: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_DOWNSTREAM_ORDERS_V2_MULTIPLE_SUPPLY || '',
  ),
  ENABLE_FEATURE_SALES_DOWNSTREAM_ORDERS_V2_SUPPLY_VALIDATION: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SALES_DOWNSTREAM_ORDERS_V2_SUPPLY_VALIDATION || '',
  ),
  ENABLE_FEATURE_FISMO_REQUEST_CANCELLATION_REASON: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_FISMO_REQUEST_CANCELLATION_REASON || '',
  ),
  ENABLE_FEATURE_PROMO_FARMER_IMPORT_CREATE: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_PROMO_FARMER_IMPORT_CREATE || '',
  ),
  ENABLE_FEATURE_STOCK_ADJUSTMENT_NOTES: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_STOCK_ADJUSTMENT_NOTES || '',
  ),
  ENABLE_FEATURE_PICKING_STATUS_INBOUND_REQUEST: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_PICKING_STATUS_INBOUND_REQUEST || '',
  ),
  ENABLE_FEATURE_ETA_DATE_OUTBOUND_DOCUMENT: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_ETA_DATE_OUTBOUND_DOCUMENT || '',
  ),
  ENABLE_FEATURE_PROMOTION_GOAL: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_PROMOTION_GOAL || '',
  ),
  ENABLE_FEATURE_STOCK_ADJUSTMENT_V2: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_STOCK_ADJUSTMENT_V2 || '',
  ),
  ENABLE_FEATURE_STOCK_ADJUSTMENT_RETRY_V2: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_STOCK_ADJUSTMENT_RETRY_V2 || '',
  ),
  ENABLE_FEATURE_CALCULATION_PAYMENT_RECAP: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_CALCULATION_PAYMENT_RECAP || '',
  ),
  ENABLE_FEATURE_BUYER_ORDER_FRAUD: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_BUYER_ORDER_FRAUD || '',
  ),
  ENABLE_FEATURE_FEE_TERM_PAYMENT_ENUMERATION: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_FEE_TERM_PAYMENT_ENUMERATION || '',
  ),
  ENABLE_FEATURE_BUYER_LEAD_BD_ORDER: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_BUYER_LEAD_BD_ORDER || '',
  ),
  ENABLE_FEATURE_SETTING_SUPPLYKU_RM_SIGNED: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_SETTING_SUPPLYKU_RM_SIGNED || '',
  ),
  ENABLE_FEATURE_CREATE_LEADS: ['true', '1'].includes(
    process.env.REACT_APP_ENABLE_FEATURE_CREATE_LEADS || '',
  ),
};
